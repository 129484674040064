import { HttpClient, HttpHeaders, HttpParamsOptions } from '@angular/common/http'

import { Component, OnInit, Input, Output, Inject, EventEmitter, PLATFORM_ID,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import KRGlue from '@lyracom/embedded-form-glue'
import { firstValueFrom } from 'rxjs'
import { apiUrl } from "../../global/global";
import { HttpParams } from "@angular/common/http";
import { AdminService } from 'src/app/services/admin.service'
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { I18nService } from 'src/app/services/i18n.service';
const DATA_KEY = makeStateKey<any>('data');

@Component({
  selector: 'app-payment-mobile',
  templateUrl: './payment-mobile.component.html',
  styleUrls: ['./payment-mobile.component.css']
})
export class PaymentMobileComponent implements OnInit {
  [x: string]: any
  title: string = 'Angular example'
  message: string = '';
  hidePayment:boolean = false;
  bookingId:any='';
  isWalletPayment:any= '';
  tokenPayment:any='';
  constructor(private http: HttpClient, private chRef: ChangeDetectorRef,   private router: Router,    private route: ActivatedRoute, private api: AdminService, private transferState: TransferState, private languge :I18nService,
    @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // console.log('params', params)
      this.bookingId = params['bookingId'];
      this.isWalletPayment = params['isWalletPayment'];
      
      this.tokenPayment  = params['token'] ?  params['token'] :'';
      // this.isLanguage = params['isLanguage'];
      // localStorage.setItem('language', this.isLanguage)
      // let languageSet =  this.isLanguage == 1 ? 'sp' :  this.isLanguage == 2 ? 'en' :'pt';
      
      // this.languge.fetch(languageSet)

  });

 
  }




  ngAfterViewInit() {

    if (isPlatformServer(this.platformId)) {
      
    } else {
      this.getPayment(); 
    } 

    
  }
  getPayment(){
    const endpoint = 'https://static.micuentaweb.pe'
    
    // live key
    // const publicKey = '97852201:publickey_r8oTwfeKXs5e1Mo2fmONvJ7fbAawZuH8uKRL4WsBEZU17'
    
    // sandbox key
    const publicKey ='57195590:testpublickey_GHRPjHcOfuo1TeyyhfVaj0hteFC7MIleA6aoN6cgBwZlS'
    let formToken = 'DEMO-TOKEN-TO-BE-REPLACED'
 
    let params = new HttpParams();
    params = params.append('bookingId', this.bookingId);
    params = params.append('isWalletPayment', this.isWalletPayment);
    if(this.tokenPayment)
      params = params.append('cardToken', this.tokenPayment);

    const data = this.api.getData(
      apiUrl._payment, params
 )
    firstValueFrom(data)
      .then((resp: any) => {
        // console.log('resp', resp)
        formToken = resp.data.token.answer.formToken
        return KRGlue.loadLibrary(
          endpoint,
          publicKey
        ) /* Load the remote library */
      })
      .then(({ KR }) =>
        KR.setFormConfig({
          /* set the minimal configuration */
          formToken: formToken,
          'kr-language': 'en-US' /* to update initialization parameter */
        })
      )
      .then(({ KR }) => KR.onSubmit(this.onSubmit))
      .then(({ KR }) =>
        KR.renderElements('#myPaymentForm')
      ) /* Render the payment form into the specified selector*/
      .catch(error => {
        // console.log('error', error)
        this.router.navigate(["/paymentError"])
        this.message = error.error.message
      })
  }

  private onSubmit = (paymentData: KRPaymentResponse) => {


    this.api
      .postData(apiUrl._paymentSucess, paymentData)
      .subscribe((response: any) => {
        if (response) {
          this.message = 'Payment successful!'
          this.hidePayment = true;
          this.router.navigate(["/paymentSuccess"])
          this.chRef.detectChanges()
        }
      })
  }
}
